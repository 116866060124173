<template>
    <h3 class="mb-1-5" id="Clients">Clientes Satisfechos </h3>

    <swiper
        :slides-per-view="3"
        :space-between="50"
        :autoplay="{
            delay: 2500,
            disableOnInteraction: false,
        }"
        :modules="modules"
    >
        <swiper-slide v-for="(item, index) in data" :key="index">
            <div class="box">
                <img :src="loadImage(item.image)" alt="Cliente">
            </div>
            <div class="watermark"> {{ item.name }} </div>
        </swiper-slide>
    </swiper>

</template>
<script lang="ts">
import IClient from '@/interfaces/iClient';
import { defineComponent } from 'vue';

import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';

export default defineComponent({
    name: "MyClients",
    data() {
        return {
            data: [] as IClient[],
        }
    },
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        return {
            modules: [Autoplay, Pagination, Navigation],
        };
    },
    mounted() {
        fetch('./db/MyClients.json')
        .then(resp => resp.json())
        .then(data => this.data = data.data as IClient[])
        .catch(()=> this.data = [] as IClient[])
    },
    methods: {
        loadImage(urlImg: string): string{
            return require(`@/assets/clients/${urlImg}`);
        },
    }
})
</script>
<style scoped>
    .box {
        height: 300px;
        position: relative;
        transition: all 400ms;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .box img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
    }

    .watermark {
        position: absolute;
        top: 10px;
        right: 10px;
        background: rgba(0, 0, 0, 0.5);
        color: white;
        padding: 5px;
        border-radius: 5px;
    }

</style>