<template>
    <div>
        Copyright: © {{ new Date().getFullYear() }} Carlos Gomez H. 
        <p>
            Reservados todos los derechos.
        </p>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'FooterComponent'
})
</script>

<style scoped>
    div {
        text-align: center;
        padding-top: 3.3rem;
    }
</style>