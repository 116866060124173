<template>
    <div class="body-time-line">
        <article>
            <dl>
                <div v-for="(item, index) in data" :key="index" class="cell">
                    <div class="cell-content">
                        <dt>
                            {{ item.initial_date}} - {{ item.final_date == null ? 'Actual' : item.final_date }}
                            <br>
                            {{ item.name  }}
                        </dt>
                        <dd>
                            <div class="content card">
                                {{ item.company }} 
                                <p>{{ item.technology }}</p>
                            </div>

                            <p class="description">
                                {{ item.description }}
                            </p>
                        </dd>
                    </div>
                </div>
            </dl>
        </article>

    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import IExperience from '@/interfaces/iExperience';

    export default defineComponent({
        name: 'TimelineComponent',
        props: {
            data: {
                type:Array as ()=> IExperience[],
                required: true,
                default: Array as ()=> IExperience[],
            },
        },
    });
</script>

<style scoped>
    div.body-time-line {
        --line: #a6a7b5;
        width: 100%;
        overflow-x: scroll;
        padding-bottom: 1.5rem;
    }

    article {
        padding: .25rem .5rem 0 1rem;
        background: var(--background);
    }

    dl{
        display: grid;
        grid-auto-columns: max-content;
        grid-auto-flow: column;
        grid-template-rows: auto auto;
    }

    dl div.cell {
        grid-column: span 2;
        display: flex;
        padding: 0 1rem;
    }

    dl div.cell:nth-child(2){
        grid-column: span 3;
        justify-content: flex-end;
    }

    dl div.cell div.cell-content {
        display: inline-flex;
        flex-direction: column;
        border-left: 1px dashed var(--line);
        position: relative;
        background: var(--background);
    }

    dl div.cell div.cell-content::after{
        content: '';
        display: block;
        background: var(--line);
        height: .5rem;
        width: .5rem;
        position: absolute;
        left: -.29rem;
        border-radius: 50%;
    }

    dl div.cell div.cell-content dt {
        background: var(--background);
        color: var(--primary);
        padding-bottom: .25em;
        position: relative;
        left: -.25rem;
        text-transform: uppercase;
        font-weight: 700; 
    }

    dl div.cell div.cell-content dd p {
        max-width: 314px;
    }

    dl div.cell div.cell-content dd div.content {
        max-width: 314px;
        margin: 0.75rem 0;
    }

    dl div.cell div.cell-content dd {
        background-color: var(--background);
        margin: 0;
        position: relative;
        left: -.25rem;
    } 
    dl div.cell:nth-child(odd) div.cell-content {
        padding-bottom: 1rem;
        border-bottom: 1px solid var(--line);
    } 

    dl div.cell:nth-child(odd) div.cell-content::after {
        bottom: -.25rem;
    }

    dl div.cell:nth-child(odd) div.cell-content dd:last-child {
        padding-bottom: .5rem;
    }

    dl div.cell:nth-child(even) div.cell-content {
        padding-top: 1rem;
        position: relative;
        top: -1px;
        border-top: 1px solid var(--line);
    }

    dl div.cell:nth-child(even) div.cell-content::after {
        top: -.25rem
    }
    dl div.cell:nth-child(even) div.cell-content dt {
        padding-top: .5rem;
    }

    dl div.cell:nth-child(even) div.cell-content dd {
        flex-grow: 1;
    }

    dl div.cell:last-child div.cell-content {
        border-top-color: transparent;
        border-bottom-color: transparent;
    }

</style>