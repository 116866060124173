import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6a2e75da"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home margin-h-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_Header = _resolveComponent("Header")!
  const _component_Experience = _resolveComponent("Experience")!
  const _component_Skill = _resolveComponent("Skill")!
  const _component_Services = _resolveComponent("Services")!
  const _component_MyClients = _resolveComponent("MyClients")!
  const _component_MyProjects = _resolveComponent("MyProjects")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("main", null, [
    _createVNode(_component_Navbar),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Header),
      _createVNode(_component_Experience),
      _createVNode(_component_Skill),
      _createVNode(_component_Services),
      _createVNode(_component_MyClients),
      _createVNode(_component_MyProjects),
      _createVNode(_component_Footer)
    ])
  ]))
}