import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a8da43a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "skill" }
const _hoisted_2 = { class: "skill-bar" }
const _hoisted_3 = { class: "col-fixed" }
const _hoisted_4 = ["alt", "src"]
const _hoisted_5 = { class: "col" }
const _hoisted_6 = { class: "info" }
const _hoisted_7 = { class: "progress-line" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.skill, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "bar mb-1-5 grid"
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("img", {
              class: "mb-1-5",
              alt: item.name,
              src: _ctx.loadImage(item.image)
            }, null, 8, _hoisted_4)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("span", null, _toDisplayString(item.name), 1)
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("span", {
                style: _normalizeStyle({width: item.value + '%'})
              }, null, 4)
            ])
          ])
        ]))
      }), 128))
    ])
  ]))
}