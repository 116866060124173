<template>
    <div class="skill">
        <div class="skill-bar">
            <div v-for="(item, index) in skill" :key="index"  class="bar mb-1-5 grid">
                <div class="col-fixed">
                    <img class="mb-1-5" :alt="item.name" :src="loadImage(item.image)" />
                </div>
                <div class="col">
                    <div class="info">
                        <span>{{ item.name }}</span>
                    </div>
                    <div class="progress-line">
                        <span :style="{width: item.value + '%'}"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import ISkill from '@/interfaces/iSkill';
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'SkillBar',
        props: {
            skill: {
                type: Array as ()=> ISkill[],
                required: true,
                default: Array as ()=> ISkill[],
            }
        },
        methods: {
            loadImage(urlImg: string): string{

                return require(`@/assets/skills/${urlImg}`);

            }
        }
    });
</script>

<style scoped>

    div.bar.grid div.col-fixed{
        width: 80px;
        height: 80px;
    }

    div.bar.grid div.col-fixed img{
        width: 100%;
        height: 100%;
        padding: .8rem;

    }

    div.bar.grid {
        display: flex;
    }
    
    div.bar.grid .col {
        flex-grow: 1;
        margin: auto 0;
        padding: 0 0.5rem;
        flex-basis:0;
    }

    .skill-bar .bar .info{
        padding-bottom: .8rem;
    }
    .skill-bar .bar .progress-line{
        position: relative;
        height: 10px;
        background: #f0f0f0;
        border-radius: 10px;
    }

    .bar .progress-line span{
        position: absolute;
        background: #25b79f;
        height: 100%;
        border-radius: 10px;
    }
</style>