<template>
    <h3 class="mb-1-5" id="Services">Mis Servicios</h3>
    <div class="grid mb-1-5">
        <ServicesCard 
            class="col"
            name="Desarrollo Full-Stack y Móvil"
            description="
                Como un desarrollador versátil y apasionado, ofrezco una amplia gama de 
                servicios que abarcan el desarrollo front-end, back-end y móvil. 
                Mi enfoque no solo está en crear soluciones atractivas y funcionales, 
                sino en garantizar que tu proyecto esté listo para el crecimiento futuro. 
                Desde el diseño de la interfaz de usuario hasta la escalabilidad del back-end y 
                las aplicaciones móviles, estoy aquí para transformar tus ideas en realidades 
                digitales sólidas y adaptables.
            "
            img="Full_Stack.png"
        />
        <ServicesCard 
            class="col"
            name="Servicios de Soporte y Mantenimiento para Proyectos en Máquinas y Servidores"
            description="
                Ofrezco servicios de soporte y mantenimiento para proyectos desplegados 
                en máquinas y servidores. Mi equipo se encarga de garantizar el funcionamiento 
                continuo y eficiente de las aplicaciones, realizando tareas de monitoreo, 
                resolución de problemas, actualizaciones de software, y optimización de 
                rendimiento. Nuestro objetivo es proporcionar a nuestros clientes tranquilidad 
                y confianza en el funcionamiento de sus sistemas, permitiéndoles centrarse en su core 
                business mientras nosotros nos ocupamos de la infraestructura tecnológica.
            "
            img="Soporte_mantenimiento.png"
        />
    </div>

    <div class="grid mb-1-5">
        
        <ServicesCard 
            class="col"
            name="Consultoría de Desarrollo Digital"
            description="
                Como consultor en desarrollo digital, 
                ofrezco orientación experta para optimizar tus proyectos tecnológicos. 
                Ya sea que necesites asesoramiento en estrategia, arquitectura de software, 
                seguridad, optimización de rendimiento o toma de decisiones críticas en el desarrollo, 
                estoy aquí para ayudarte. Mi enfoque es brindar soluciones efectivas y escalables que 
                impulsen tu éxito en el mundo digital.
            "
            img="Consultoria_Desarrollo_Digital.png"
        />
        <ServicesCard
            class="col"
            name="Gestión de Proyectos Digitales Eficiente"
            description="
                Como gestor de proyectos digitales, estoy dedicado a garantizar que tus 
                iniciativas tecnológicas se entreguen de manera efectiva, dentro del presupuesto 
                y en el plazo establecido. Desde la planificación y asignación de recursos hasta la 
                supervisión y control de cada etapa del proyecto, mi enfoque es brindar resultados exitosos 
                y satisfacer tus objetivos empresariales.
            "
            img="Gestion_Proyectos Digitales.png"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ServicesCard from "./ServicesCard.vue";


export default defineComponent ({
    name: 'ServicesComponent',
    components: {
        ServicesCard,
    }
});
</script>