import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3a84bf78"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container card" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      class: "margin-h-auto",
      src: require(`@/assets/icons/${_ctx.img}`)
    }, null, 8, _hoisted_2),
    _createElementVNode("h1", null, _toDisplayString(_ctx.name), 1),
    _createElementVNode("div", {
      class: "",
      innerHTML: _ctx.description
    }, null, 8, _hoisted_3)
  ]))
}