<template>
  <main>
    <Navbar></Navbar>
    <div class="home margin-h-auto">
      <Header></Header>
      <Experience></Experience>
      <Skill></Skill>
      <Services></Services>
      <MyClients></MyClients>
      <MyProjects></MyProjects>
      <Footer></Footer>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Navbar from '@/components/home_view/Navbar.vue';
import Header from '@/components/home_view/Header.vue';
import Experience from '@/components/home_view/Experience.vue';
import Skill from '@/components/home_view/Skill.vue';
import Services from "@/components/home_view/Services.vue";
import MyClients from '@/components/home_view/MyClients.vue';
import MyProjects from '@/components/home_view/MyProjects.vue';
import Footer from '@/components/home_view/Footer.vue';


export default defineComponent({
  name: 'HomeView',
  components: {
    Navbar,
    Header,
    Experience,
    Skill,
    Services,
    MyClients,
    MyProjects,
    Footer
  },
});
</script>

<style scoped>
  main{
    padding-bottom: 2rem;
    display: block;
  }

  @media screen and (max-width: 780px) {
    div.home {
        padding: 0 30px;
    }
  }

  div.home {
    padding-top: 25px;
    max-width: 768px;
    position: relative; 
    transform: none;
  }

</style>
