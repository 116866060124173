<template>
    <div class="container">
        <div class="menu margin-h-auto">
            <div class="menu-title">
                <h1>Carlos Gomez H</h1>
            </div>
            <div class="menu-item" v-if="windowWidth > 577">
                <ul>
                    <li>
                        <a :class="{'active' : activeSection === 'Experience'}" href="#" @click="scrollToSection('Experience', $event)">Experiencia</a>
                    </li>
                    <li>
                        <a :class="{'active' : activeSection === 'Skill'}" href="#" @click="scrollToSection('Skill', $event)">Skill</a>
                    </li>
                    <li>
                        <a :class="{'active' : activeSection === 'Services'}" href="#" @click="scrollToSection('Services', $event)">Mis Servicios</a>
                    </li>
                    <li>
                        <a :class="{'active' : activeSection === 'Clients'}" href="#" @click="scrollToSection('Clients', $event)">Clientes Satisfechos</a>
                    </li>
                    <li>
                        <a :class="{'active' : activeSection === 'Projects'}" href="#" @click="scrollToSection('Projects', $event)">Mis Proyectos</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'NavbarComponent',
    data() {
        return {
            windowWidth: window.innerWidth,
            activeSection: ''
        }
    },
    methods: {
        scrollToSection(section: string, event: MouseEvent){
            const targetSection = document.getElementById(section);
            if (targetSection) {

                event.preventDefault();
                
                window.scrollTo({
                    top: targetSection.offsetTop,
                    behavior: 'smooth',
                });

                this.activeSection = section;
            }
        },
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
        handleScroll(){
            this.validateTarget("Experience")
            this.validateTarget("Skill")
            this.validateTarget("Services")
            this.validateTarget("Clients")
            this.validateTarget("Projects")
        },
        validateTarget(section: string){
            const target = document.getElementById(section);
            if (target) {
                const rect = target.getBoundingClientRect();
                if (rect.top >= 0 && rect.top < window.innerHeight) {
                    this.activeSection = section;
                }
            }
        }
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize',this.handleResize)
        this.handleResize();
    },
    beforeCreate() {
        window.removeEventListener('resize', this.handleResize);
        window.removeEventListener('scroll', this.handleScroll);
    },
});
</script>

<style scoped>
    div.container {
        background: #20202380;
        position: fixed;
        width: 100%;
        z-index: 2;
    }

    div.container div.menu {
        padding: 0.5rem;
        display: flex;
        max-width: 768px;;
    }

    div.menu div.menu-title {
        margin-right: 1.25rem;
    }

    div.menu div.menu-title h1 {
        font-weight: 700;
        font-size: 1.3rem;
        color: var(--color-text);
    }

    div.menu div.menu-item ul{
        list-style: none;
        padding: 0;
        display: flex;
        height: 100%;
    }

    div.menu div.menu-item ul li {
        margin: auto 10px;
    }

    div.menu div.menu-item ul li a{
        text-decoration: none;
        color: white;
    }

    ul a.active {
        background-color: var(--primary);
        padding: .25rem .8rem;
        border-radius: 5px; 
    }
</style>