<template>
    <h3 class="mb-1-5" id="Skill">Skill</h3>
    <div class="grid">
        <div class="col">
            <div>
                <h3 class="mb-1-5">Framework</h3>
                <SkillBar :skill="framework"></SkillBar>
            </div>
        </div>
        <div class="col">
            <div>
                <h3 class="mb-1-5">Lenguaje de programacion</h3>
                <SkillBar :skill="lenguaje"></SkillBar>
            </div>
        </div>
        <div class="col">
            <div>
                <h3 class="mb-1-5">Base de dato</h3>
                <SkillBar :skill="dataBase"></SkillBar>
            </div>
        </div>
    </div>

</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ISkill from '@/interfaces/iSkill';
import TypeSkill from '@/enums/typeSkill';

import SkillBar from './SkillBar.vue';

export default defineComponent({
    name: 'SkillComponent',
    components: {
        SkillBar,
    },
    data() {
        return {
            skills: [] as ISkill[]
        }
    },
    computed: {
        framework(): ISkill[] {
            return this.skills.filter((item: ISkill) => item.type === TypeSkill.framework)
        },
        lenguaje(): ISkill[] {
            return this.skills.filter((item: ISkill) => item.type === TypeSkill.language)
        },
        dataBase(): ISkill[] {
            return this.skills.filter((item: ISkill) => item.type === TypeSkill.db)
        }
    },
    mounted() {
        fetch('./db/Skill.json')
        .then(resp => resp.json())
        .then(data => {this.skills = data.data.sort((a:ISkill, b:ISkill)=>b.value - a.value)})
        .catch(() => this.skills = [] as ISkill[]);
    }
});
</script>