<template>
    <div>
        <h3 class="mb-1-5" id="Experience">Experiencia</h3>
        <div class="mb-1-5">
            <Timeline :data="data"></Timeline>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import Timeline from './Timeline.vue';
import IExperience from '@/interfaces/iExperience';

export default defineComponent({
    name: 'ExperiencieComponent',
    components: {
        Timeline,
    },
    data() {
        return {
            data: []
        }
    },
    mounted() {
        fetch('./db/MyExperience.json')
        .then(resp => resp.json())
        .then(data => this.data = data.data.sort((a:IExperience, b:IExperience) =>  b.id - a.id))
        .catch(() => this.data = []);
    },
});
</script>