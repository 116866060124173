<template>
    <h3 class="mb-1-5" id="Projects">Mis Proyectos</h3>
    <div class="container">
        <div v-for="(item, index) in data" 
            :key="index" 
            class="box box-1"
            :data-text="item.name"
            :style="{ background: `url(${require('@/assets/proyects/'+item.urlImg)}) center center / cover` }"
            >
            <div class="watermark"> {{ item.status }} </div>
        </div>
    </div>
</template>

<script lang="ts">
import IProyect from '@/interfaces/iProyect';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'MyProjects',
    data() {
        return {
            data: [] as IProyect[]
        }
    },
    mounted() {
        fetch('./db/Projects.json')
        .then(resp => resp.json())
        .then(data => this.data = data.data as IProyect [])
        .catch(() => this.data = [] as  IProyect[])
    },
});
</script>

<style scoped>
    .container {
        position: relative;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1em;
        height: 200px;
        transition: all 400ms;
    }

    .box {
        position: relative;
        transition: all 400ms;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .box::after {
        content: attr(data-text);
        position: absolute;
        bottom: 20px;
        background: #000;
        padding: 10px 10px 10px 10px;
        text-transform: uppercase;
        transform: translateY(60px);
        opacity: 0;
        transition: all 400ms;
    }

    .container:hover .box {
        filter: grayscale(100%) opacity(24%);
    }

    .box:hover::after {
        transform: translateY(0);
        opacity: 1;
        transition-delay: 400ms;
    }

    .container .box:hover {
        filter: grayscale(0%) opacity(100%);
    }

    .watermark {
        position: absolute;
        top: 10px;
        right: 10px;
        background: rgba(0, 0, 0, 0.5);
        color: white;
        padding: 5px;
        border-radius: 5px;
    }

</style>