<template>
    <div class="header">
        <img class="mb-1-5" alt="Vue logo" src="@/assets/photoV2.jpg" />
        <h1 class="mb-1-5">
            Carlos Ivan Gomez Hernandez
        </h1>
        <SocialNetworks class="mb-1-5"></SocialNetworks>
        <p class="mb-1-5">
            Ingeniero de sistemas con un diplomado en gestión de proyecto, con bases en de programación orientada a objeto, código limpio y arquitectura hexagonal, con experiencia en desarrollo de aplicaciones móviles nativa e hibridas, web usando vuejs, angular y backend con laravel, Django, spring boot.
        </p>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import SocialNetworks from "./socialNetworks.vue";

export default defineComponent({
    name: 'HeaderComponent',
    components: {
        SocialNetworks
    }
});
</script>

<style scoped>
    div.header {
        text-align: center;
    }
    
    div.header img {
        border: 2px solid white;
        border-radius: 50%;
        width: 100px;
        height: 100px;
        object-fit: cover;
        widows: 100px;
    }
</style>