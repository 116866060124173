<template>
    <div class="container card">
        <img class="margin-h-auto" :src="require(`@/assets/icons/${img}`)" />
        <h1>{{ name }}</h1>
        <div class="" v-html="description"></div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';


export default defineComponent ({
    name: 'ServicesCard',
    props: {
        img: {
            type: String,
            required: true
        },
        name: {
            type: String,
            required: true
        },
        description: {
            type: String,
            required: true
        }
    }
});
</script>

<style scoped>
    div.container {
        text-align: center;
        margin: 0 .3rem;
    }

    div.container div {
        padding: 1.3rem 1.3rem;
        text-align: left;
    }

    img {
        width: 150px;
    }
</style>